<template>
  <PDialog
    title="Margin Management"
    :position="dialogPosition"
    :hideClose="isMobile"
    @close="toggleMarginManagement(false)"
  >
    <div class="w-full sm:w-96 flex flex-col space-y-5">
      <PToggle :items="items" />
      <div class="border rounded p-3 flex flex-col space-y-3">
        <div
          v-for="field in fields"
          :key="field.label"
          class="flex text-sm justify-between"
        >
          <div class="flex-none mr-3">{{ field.label }}</div>
          <div class="flex space-x-1 items-center overflow-hidden">
            <span>{{ field.prevValue }}</span>
            <ArrowRightIcon class="h-3 flex-none" />
            <span
              class="font-bold truncate"
              :class="`text-${
                field.color && field.nextValue !== '-' ? field.color : 'white'
              }`"
            >
              {{ field.nextValue }}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="flex justify-between">
          <p class="text-sm mb-1">Please enter the margin</p>
          <p class="text-sm mb-1" v-if="showMaxAmount">
            Max
            <span class="text-white text-sm font-medium">
              {{ maxAmount }} USDT
            </span>
          </p>
        </div>
        <CurrencyInput
          v-model.number="amount"
          currency="USDT"
          placeholder="0.00"
          :error="error"
          dataCy="margin-management-dialog_amount-input"
          @input="resetError"
        />
      </div>
      <div class="flex justify-center">
        <PButton
          v-if="!isLoading"
          @click="handleClick"
          class="w-full"
          :color="submitColor"
          size="lg"
        >
          {{ submitLabel }}
        </PButton>
        <transition name="fade">
          <SmallLoader class="my-3" v-if="isLoading" />
        </transition>
      </div>
    </div>
  </PDialog>
</template>
<script>
import { watch, ref, computed } from 'vue'
import Big from 'big.js'
import SmallLoader from './common/SmallLoader.vue'
import { useDialogs } from '@/hooks/useDialogs'
import { addMargin, removeMargin } from '@/contracts/marginManagement'
import { getAmmContractAddress } from '@/contracts/contracts'
import { getFreeCollateral } from '@/contracts/position'
import { useAMM } from '@/hooks/useAMM'
import { useUserPosition, fetchAllPositions } from '@/hooks/useUserPosition'
import useBreakpoints from '@/hooks/useBreakpoints'
import { fetchSpotPrice } from '../formatters/price'
import { mapState } from 'vuex'
import { PToggle, PDialog, PButton } from '../../palmswap-vue-ui'
import CurrencyInput from '@/components/trade/make-order/CurrencyInput'
import { ArrowRightIcon } from '@heroicons/vue/24/solid'
import { getLiquidationPrice } from '@/contracts/helpers'
import { useAppState } from '@/hooks/useAppState'

const TAB_ADD_MARGIN = 0
const TAB_REMOVE_MARGIN = 1

export default {
  components: {
    CurrencyInput,
    PDialog,
    PButton,
    PToggle,
    SmallLoader,
    ArrowRightIcon
  },
  setup() {
    const { getMaxPosition } = useAMM()
    const {
      currencySymbol: defaultCurrencySymbol,
      pairSymbolAmm: defaultPairSymbolAmm
    } = useAppState()
    const { toggleMarginManagement, metadata } = useDialogs()

    const pairSymbolAmm = computed(() => {
      if (metadata.value.pairSymbol) {
        return metadata.value.pairSymbol.replace('/', '')
      }
      return defaultPairSymbolAmm
    })
    const currencySymbol = computed(() => {
      if (metadata.value.pairSymbol) {
        return metadata.value.pairSymbol.split('/')[0]
      }
      return defaultCurrencySymbol
    })

    const { rawPositions } = useUserPosition()
    const currentPosition = computed(
      () => rawPositions.value.find((i) => i.pair === pairSymbolAmm.value) || {}
    )

    const maxPosition = ref(0)
    const spotPrice = ref(new Big(0))
    const freeCollateral = ref(new Big(0))
    const { isMobile } = useBreakpoints()

    watch(
      pairSymbolAmm,
      async () => {
        if (pairSymbolAmm.value) {
          maxPosition.value = await getMaxPosition(pairSymbolAmm.value)
          spotPrice.value = await fetchSpotPrice(pairSymbolAmm.value)
        }
      },
      { immediate: true }
    )

    watch(
      currentPosition,
      async (position) => {
        const _freeCollateral = await getFreeCollateral(
          position.amm,
          position.trader
        )
        freeCollateral.value = _freeCollateral
      },
      { immediate: true }
    )

    return {
      currencySymbol,
      toggleMarginManagement,
      pairSymbolAmm,
      maxPosition,
      currentPosition,
      spotPrice,
      freeCollateral,
      isMobile
    }
  },
  data() {
    return {
      isLoading: false,
      amount: null,
      error: null,
      tabSelected: TAB_ADD_MARGIN,
      items: [
        {
          text: 'Add Margin',
          color: 'green',
          dataCy: 'margin-management-dialog_add-margin-button',
          click: () => this.setTabSelected(TAB_ADD_MARGIN)
        },
        {
          text: 'Reduce Margin',
          color: 'red',
          dataCy: 'margin-management-dialog_add-reduce-button',
          click: () => this.setTabSelected(TAB_REMOVE_MARGIN)
        }
      ]
    }
  },
  methods: {
    setTabSelected(value) {
      this.tabSelected = value
      this.resetError()
    },
    validate() {
      const a = Number(this.amount)
      const ma = Number(this.maxAmount)

      if (a === 0 || a === '') return "Margin can't be 0"
      if (this.nextLeverage.lt(1)) return "Leverage can't be less than 1x"
      if (this.nextLeverage.gt(10)) return "Leverage can't exceed 10x"
      if (
        this.walletBalance < this.amount &&
        this.tabSelected === TAB_ADD_MARGIN
      ) {
        return 'The available balance is not enough to add margin'
      }
      if (ma === 0) return `Max available margin is 0, but you set ${a}`
      if (a > ma) return 'Margin exceeds max amount'
      return null
    },
    async handleClick() {
      this.error = this.validate()
      if (this.error) {
        return
      }
      const id = Date.now()
      this.$store.commit('addId', id)
      const fields = this.fields
      this.$notify({
        id,
        title:
          this.tabSelected === TAB_ADD_MARGIN
            ? `Add $${this.amount} Margin to ${this.currencySymbol} Position`
            : `Reduce $${this.amount} Margin from ${this.currencySymbol} Position`,
        type: 'progress',
        duration: -1,
        data: {
          kind: 'margin',
          fields
        }
      })
      try {
        this.isLoading = true
        let receipt
        const ammAddr = getAmmContractAddress(this.pairSymbolAmm)
        this.toggleMarginManagement(false)
        if (this.tabSelected === TAB_ADD_MARGIN) {
          receipt = await addMargin(
            this.walletAddress,
            ammAddr,
            this.amount,
            this.signer
          )
        }
        if (this.tabSelected === TAB_REMOVE_MARGIN) {
          receipt = await removeMargin(
            this.walletAddress,
            ammAddr,
            this.amount,
            this.signer
          )
        }
        if (!receipt) throw Error('add/remove margin failed')
        await this.$store.dispatch('updateBalance')
        this.$notify({
          id,
          title:
            this.tabSelected === TAB_ADD_MARGIN
              ? `Add $${this.amount} Margin to ${this.currencySymbol} Position`
              : `Reduce $${this.amount} Margin from ${this.currencySymbol} Position`,
          type: 'success',
          duration: -1,
          data: {
            kind: 'margin',
            fields
          }
        })
        fetchAllPositions()
      } catch (err) {
        console.error(err)
        this.$notify({
          id,
          title:
            this.tabSelected === TAB_ADD_MARGIN
              ? `Add $${this.amount} Margin to ${this.currencySymbol} Position`
              : `Reduce $${this.amount} Margin from ${this.currencySymbol} Position`,
          type: 'error',
          data: {
            kind: 'margin',
            fields
          }
        })
      } finally {
        this.toggleMarginManagement(false)
        this.$notify.close(id)
        this.$store.commit('removeId', id)
        this.isLoading = false
      }
    },
    resetError() {
      this.error = null
    }
  },
  computed: {
    ...mapState({
      walletBalance: (state) => state.user.balance.wallet, // user wallet balance (metamask, walletconnect)
      walletAddress: (state) => state.user.address.wallet,
      signer: (state) => state.web3Modal.provider.getSigner()
    }),
    availableFormatted() {
      return (+this.walletBalance).toFixed(2)
    },
    submitColor() {
      return this.tabSelected === TAB_ADD_MARGIN ? 'success' : 'danger'
    },
    submitLabel() {
      const verb = this.tabSelected === TAB_ADD_MARGIN ? 'Increase' : 'Reduce'
      return `${verb} Margin`
    },
    fields() {
      const position = this.currentPosition
      const amountBn = new Big(this.amount || 0)
      const renderNextValue = (value) => {
        return amountBn.gt(0) ? value : '-'
      }

      const nextLiquidationPrice = getLiquidationPrice(
        position.size,
        position.entryPrice,
        this.nextLeverage
      )
      return [
        {
          label: 'Leverage Ratio',
          prevValue: position.leverage.toFixed(2),
          nextValue: renderNextValue(
            this.nextLeverage.gt(0) ? this.nextLeverage.toFixed(2) : 0
          )
        },
        {
          label: 'Liquidation Price',
          prevValue: `$${position.liquidationPrice.toFixed(2)}`,
          nextValue: renderNextValue(
            `$${
              nextLiquidationPrice.gt(0) ? nextLiquidationPrice.toFixed(2) : 0
            }`
          ),
          color: this.tabSelected === TAB_ADD_MARGIN ? 'green' : 'warning'
        },
        {
          label: 'Current Margin',
          prevValue: `$${position.margin.toFixed(2)}`,
          nextValue: renderNextValue(
            `$${this.nextMargin.gt(0) ? this.nextMargin.toFixed(2) : 0}`
          )
        }
      ]
    },
    nextLeverage() {
      return this.currentPosition.openNotional.div(this.nextMargin)
    },
    nextMargin() {
      const method = this.tabSelected === TAB_ADD_MARGIN ? 'add' : 'sub'
      const amountBn = new Big(this.amount || 0)
      return this.currentPosition.margin[method](amountBn)
    },
    maxAmount() {
      const maxAmount =
        this.tabSelected === TAB_ADD_MARGIN
          ? this.maxAddAmount
          : this.maxRemoveAmount
      return maxAmount
    },
    maxAddAmount() {
      const amount1 = this.currentPosition.openNotional
        .div(10)
        .sub(this.currentPosition.margin)
      const amount2 = this.currentPosition.openNotional.sub(
        this.currentPosition.margin
      )
      const maxAmount = amount1 > amount2 ? amount1 : amount2
      return maxAmount.gt(0) ? maxAmount.toFixed(2) : 0
    },
    maxRemoveAmount() {
      const amount1 = this.currentPosition.margin.sub(
        this.currentPosition.openNotional.div(10)
      )
      const amount2 = this.currentPosition.margin.sub(
        this.currentPosition.openNotional
      )
      const maxAmount = amount1 > amount2 ? amount1 : amount2
      return maxAmount.gt(0) ? maxAmount.toFixed(2) : 0
    },
    showMaxAmount() {
      return !(
        this.currentPosition.leverage.gte(10) &&
        this.tabSelected === TAB_REMOVE_MARGIN
      )
    },
    dialogPosition() {
      if (this.isMobile) {
        return 'bottom'
      } else {
        return 'center'
      }
    }
  }
}
</script>
