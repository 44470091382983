<template>
  <div class="px-5 py-3">
    <SideSelector v-model:side="side" class="mt-3" />
    <div class="flex justify-between mt-5 mb-1 text-sm">
      <span>Size</span>&nbsp;
      <div>
        Avbl <span class="text-white">{{ formattedBalance }}</span>
      </div>
    </div>
    <AmountInput
      v-model="size"
      :error="formattedError"
      :totalCollateral="totalCollateral"
      :price="markPrice"
      :currency-symbol="currencySymbol"
    />
    <Leverage class="mt-5" v-model="leverage" :side="side" />
    <OrderDetails
      v-model:totalCost="totalCost"
      v-model:collateral="collateral"
      :isMarket="true"
      :side="side"
      :leverage="leverage"
      :size="size"
      class="my-5"
    />
    <Alert
      class="mb-5"
      v-if="error && error.title"
      :title="error.title"
      :message="error.message"
      type="error"
    />
    <PlaceOrderButtons
      :side="side"
      :totalCost="totalCost"
      :disabled="isDisabled"
      :loading="isLoading"
      @confirm="handleConfirm"
    />
  </div>
</template>
<script>
import Big from 'big.js'
import { ref, computed, unref } from 'vue'
import { openPosition } from '../../../contracts/position'
import { useAppState } from '@/hooks/useAppState'
import { useUserPosition } from '@/hooks/useUserPosition'
import AmountInput from './AmountInput.vue'
import OrderDetails from './OrderDetails.vue'
import Leverage from './Leverage.vue'
import SideSelector from './SideSelector.vue'
import Alert from '@/components/common/Alert.vue'
import { mapState } from 'vuex'
import { ammGetIsOverFluctuationLimit } from '@/contracts/amm'
import { useOrderForm } from '@/hooks/useOrderForm'
import PlaceOrderButtons from '@/components/trade/make-order/PlaceOrderButtons'
import { roundAmount } from '@/contracts/helpers'
import { useAMM } from '@/hooks/useAMM'

export default {
  components: {
    PlaceOrderButtons,
    Alert,
    AmountInput,
    SideSelector,
    Leverage,
    OrderDetails
  },
  setup(props) {
    const { slippage, pairSymbolAmm, currencySymbol } = useAppState()
    const { maxPosition, leverage, side, size, totalCollateral } =
      useOrderForm(true)
    const { markPrice } = useAMM()
    const { currentPosition } = useUserPosition()
    const totalCost = ref(new Big(0))
    const collateral = ref(new Big(0))
    const error = ref(null)

    return {
      error,
      currentPosition,
      maxPosition,
      leverage,
      slippage,
      pairSymbolAmm: computed(() => {
        if (props.pairSymbol) {
          return props.pairSymbol.replace('/', '')
        }
        return pairSymbolAmm.value
      }),
      currencySymbol: computed(() => {
        if (props.pairSymbol) {
          return props.pairSymbol.split('/')[0]
        }
        return currencySymbol.value
      }),
      markPrice,
      totalCost,
      collateral,
      totalCollateral,
      side,
      size
    }
  },
  data() {
    return {
      isButtonLoading: false
    }
  },
  computed: {
    ...mapState({
      connected: (state) => state.web3Modal.connected,
      walletBalance: (state) => state.user.balance.wallet, // user wallet balance (metamask, walletconnect)
      walletAddress: (state) => state.user.address.wallet,
      signer: (state) => state.web3Modal.provider.getSigner()
    }),
    isDisabled() {
      return (
        this.error ||
        !this.size ||
        this.size.lte(0) ||
        this.totalCost.lte(0) ||
        this.isButtonLoading
      )
    },
    formattedError() {
      if (typeof this.error !== 'string') {
        return null
      }
      return this.error
    },
    formattedBalance() {
      return this.walletBalance
        ? `${(+this.walletBalance).toFixed(2)} USDT`
        : '-'
    }
  },
  methods: {
    formatUSDT(amount) {
      return `${Number(amount).toFixed(2)} USDT`
    },
    async validate() {
      let s = this.size
      if (!s) {
        return
      }
      let maxAvailableSize = this.maxPosition
      if (this.currentPosition !== null && maxAvailableSize > 0) {
        if (this.side === this.currentPosition.side) {
          s = s.add(new Big(this.currentPosition.size)).abs()
          maxAvailableSize = roundAmount(
            new Big(maxAvailableSize).sub(this.currentPosition.size)
          ).toString()
        } else {
          s = s.sub(new Big(this.currentPosition.size)).abs()
          maxAvailableSize = roundAmount(
            new Big(maxAvailableSize).add(this.currentPosition.size)
          ).toString()
        }
      }

      if (s.lte(0)) return 'Size must be greater than 0'

      const totalCost = this.totalCost.toNumber()
      if (totalCost === 0 && s.gt(0)) return 'Total cost must be greater than 0'
      if (totalCost > this.walletBalance) {
        return {
          title: 'Insufficient Funds',
          message: 'To trade this size, please deposit more USDT.'
        }
      }
      if (this.maxPosition > 0 && s.gt(this.maxPosition)) {
        return `Max position size is ${maxAvailableSize}`
      }
      const isOverFluctuation = await ammGetIsOverFluctuationLimit(
        unref(this.pairSymbolAmm),
        s
      )
      if (isOverFluctuation) {
        return {
          title: 'Maximum size exceeded',
          message:
            'Reduce the trade size or try to divide the quantity into several orders.'
        }
      }

      return null
    },
    async handleConfirm() {
      this.isButtonLoading = true
      this.error = await this.validate()
      if (this.error) {
        return
      }

      if (!this.connected) {
        await this.$store.dispatch('connect')
      }

      const id = Date.now()
      this.$store.commit('addId', id)
      const pricePerUnit = this.totalCost.toFixed(2)
      const size = this.size.toString()
      try {
        const res = await openPosition(
          this.walletAddress,
          unref(this.pairSymbolAmm),
          this.side,
          this.collateral,
          String(this.leverage),
          this.signer,
          this.slippage,
          this.size,
          () => {
            this.$notify({
              id,
              title: 'Open Position',
              duration: -1,
              type: 'progress',
              data: {
                type: 'market',
                kind: 'order',
                side: this.side,
                price: pricePerUnit,
                size: size
              }
            })
          }
        )
        if (!res.success) {
          this.error = res.error
          return
        }
        await this.$store.dispatch('updateBalance')
        this.$notify({
          id,
          title: 'Open Position',
          type: 'success',
          data: {
            kind: 'order',
            side: this.side,
            price: pricePerUnit,
            size: size
          }
        })
      } catch (error) {
        console.error(error)
        this.$notify({
          id,
          title: 'Open Position',
          type: 'error',
          data: {
            isLimit: false,
            kind: 'order',
            side: this.side,
            price: pricePerUnit,
            size: size
          }
        })
      } finally {
        this.isButtonLoading = false
        this.$notify.close(id)
        this.$store.commit('removeId', id)
        this.amount = new Big(0)
      }
    }
  },
  props: {
    pairSymbol: {
      type: String,
      default: null
    }
  },
  watch: {
    async totalCost() {
      this.error = await this.validate()
    }
  }
}
</script>

<style lang="scss" scoped></style>
